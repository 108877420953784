import React from "react";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import TopBanner from "../../../components/database/topbanner/TopBanner";

import amazonAthenaImg from "../../../components/databasewaitlist/images/amazon-athena.png";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/amazon-athena_rest.png",
};

const canonicalUrl = "https://hasura.io/rest-api/database/amazon-athena/";

const AmazonAthena = props => (
  <Layout location={props.location}>
    <Seo
      title="Instant Realtime GraphQL and REST APIs on Amazon Athena - Hasura"
      description="Connect Hasura to existing/new Amazon Athena DBs for instant, realtime and secure GraphQL and REST APIs. "
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner
      title="Instant REST APIs on Amazon Athena"
      desc="Point to your Amazon Athena instance and get secure and performant REST APIs in under 2 minutes!"
      btnLink="https://cloud.hasura.io/signup?pg=amazon-athena&plcmt=hero&cta=try-it-out-in-30s&tech=default"
      btnText="Try it out in 30 seconds"
      imgSrc={amazonAthenaImg}
      linkUrl="https://hasura.io/docs/latest/databases/athena/index/"
      linkText="Learn more in the docs"
      secondaryLinkUrl="https://hasura.io/blog/announcing-amazon-athena-integration/"
      secondaryLinkText="Read launch blog"
    />
  </Layout>
);

export default AmazonAthena;
